// Variable overrides
$main-color: #E2F4FD;
$button-color: #314A75;
$nav-sub-text: #314A75;
$event-bg: #AAE0FA;
$event-text: #314A75;
$active-button-color: #AAE0FA;
$mamu-button: #AAE0FA;
$th-bg: #AAE0FA;
$th-color: #314A75;
$td-bg: #E2F4FD;
$td-color: #314A75;

$accent-color: #AAE0FA;
$accent-color-light: #E2F4FD;
$secondary-accent-color: #314A75;
$sub-accent-color: #E2F4FD;
$secondary-text-color: #314A75;
$header-height: auto;
