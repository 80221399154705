.sidebar{
  background: white;
  margin-top: 167px;

  .nav-link{
    background: $button-color;
    color: white;
    border-radius: 5px;
    text-transform: uppercase;
    font-family: 'Titillium Web', sans-serif;
    font-size: 14px;
  }

  .nav-item{
    margin-top: 5px;
    padding: 0 5px;
  }

  .nav-item:first-of-type{
    margin-top: 0;
  }

  .nav-link.active{
    background: $active-button-color;
    color: $button-color;
  }
}