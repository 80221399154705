.paginator{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
  margin-top: 8px;

  button {
    border: 1px solid #ddd;
    min-width: 35px;
  }

  button:hover {
    background-color: #ddd;
  }

  .page-number, .last-page{
    padding: 0 5px;
    color: $secondary-text-color;
    font-size: 9px;
    line-height: 14px;
  }
  .page-number:hover {
      background-color: $accent-color;
  }
  .selected-page-number {
    font-weight: bold;
    font-size: 12px;
    background-color: $accent-color;
  }
  .last-page, .show-pages {
    padding: 0 5px;
  }
  .show-pages {
    margin: 0 5px
  }
}