// If you want to override variables do it here
@import "variables";

// Import styles
@import "~@coreui/coreui/scss/coreui.scss";

// Temp fix for reactstrap
@import '~@coreui/coreui/scss/_dropdown-menu-right.scss';

// If you want to add something do it here
@import "Header";
@import "Navigation";
@import "Dashboard";
@import "Login";
@import "Paginator";

// ie fixes
@import "ie-fix";

// HTML =================================================
* {
  font-family: 'Titillium Web', sans-serif;
}
body{
  background-color: white;
}
.app:not(.login) {
  background-image: url("../assets/img/background/dugnas-2.png");
}
.login {
  background-image: url("../assets/img/background/dugnas-1.png");
}
button, [type="button"] {
  -webkit-appearance: none;
}
table, .table{
  width: 100%;
  border: none;
}
table.table-responsive{
  display: table;
}
table, th, td{
  border: 1px solid $secondary-accent-color;
  text-align: center;
}
table th{
  padding: 10px;
  color: $th-color;
  background-color: $accent-color;
}
table td{
  background-color: $accent-color-light;
  padding: 10px;
  color: $td-color;
}
table th.clear-css, table td.clear-css{
  background-color: transparent;
  border: none;
}
thead:not(.tableFilters) {
  color: $secondary-accent-color;
  font-size: 9px;
  font-weight: 600;
  line-height: 14px;
  text-align: center;
}
.container-fluid {
  @media (max-width: 768px) {
    padding: 0 5px;
  }
}
.item-editButton, .item-checkbox {
  min-width: 50px !important;
  width: 50px !important;
}
.item-checkbox {
  padding: 0 !important;
}
.table thead th{
  border-top: 1px solid;
  border-bottom: 1px solid;
  padding: 10px 6px;
  vertical-align: middle;

  @media (max-width: 1024px) {
    padding: 5px 2px;
  }
}
.table thead th.clear-css{
  border: none;
}
.table th, .table td{
  vertical-align: middle;
}
.table-responsive{
  overflow-x: initial;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance:textfield; /* Firefox */
}


// Bootstrap ============================================

.btn-default{
  border: 1px solid;
}
.row{
  margin-bottom: 10px;
}

// Main CSS =============================================

.show-on-respone{
  display: none;
}
.show-on-full{
  display: table-row;
}

.noselect{
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.no-margin{
  margin: 0 0 0 0;
}

.left-margin{
  margin: 0 0 0 5px;
}

.hidden{
  display: none !important;
}

.spinner{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  height: 50vh;
  z-index: 200;
}

.full-width{
  width: 100%;
}

.left-form-collumn{
  width: 50%;
  align-items: flex-start;
}

.signature{
  display: inline-block;
  width: 125px;
  height: 50px;
  background-size: contain;
  background-position: left center;
  background-repeat: no-repeat;
}

.action-button-td{
  max-width: 150px;
  min-width: 110px;
  text-align: center;

  a, .clear-search{
    margin-right: 5px;
  }
}

.action-button-td-check{
  min-width: 60px;
  text-align: center;
}

.titillium-font{
  font-family: 'Titillium Web', sans-serif;
}

// Modals
.ReactModal__Overlay{
  z-index: 1100;
}

.modal-content-container h2.log-title{
  margin-bottom: 30px;
}

.card-header{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}

.create-link{
  margin-left: 5px;
}

.edit-parent{
  width: 100%;
  display: flex;
  flex-direction: column;

  .action-buttons{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-content: center;
    align-items: center;
  }
  form{
    display: flex;
    flex-direction: column;
  }
  form label{
    display: flex;
    flex-direction: column;
  }
  .footer-actions{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    margin-top: 20px;
  }
}

.footer-action-center{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-top: 40px;
  padding-left: 15px;
  margin-bottom: 20px;

  button{
    margin: 0 10px;
  }
}

.edit-content{
  padding: 20px 20px;
}

.form-titles{
  margin-top: 30px;
  margin-bottom: 30px;
}

.one-row{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
}

.one-column{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: flex-start;
}

.header-edit{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}

.quantity-input {
  width: 70%;
  margin-right: 10%;
}

.button-whitespace {
  margin: 0 5px;
}

.preview{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.2);

  .preview-child{
    width: 50%;
    background-color: white;
    border-radius: 5px;
    padding: 20px;
    position: relative;

    .info{
      b{
        font-size: 16px;
      }
      span{
        margin-left: 10px;
      }
    }

    .close-preview{
      position: absolute;
      right: 20px;
    }
  }
}

.modal-content-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  h2 {
    text-align: center;
    margin-bottom: 15%;
  }
}

.center-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.filter-select{
  max-width: 200px;
}

.tabs{
  overflow: hidden;

  button{
    background-color: rgba(204, 204, 204, 1);
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
  }

  button.active {
    background-color: rgba(242, 242, 242, 1);
  }

  .tab-imitation{
    float: right;
    border: none;
    outline: none;
    padding: 5px 35px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background-color: $event-bg;
    margin-right: 33px;
    font-family: 'Titillium Web', sans-serif;
    text-transform: uppercase;
    color: $event-text;
    font-size: 12px;
  }
}

.item-tab-content{
  background-color: rgba(242, 242, 242, 1);
  padding: 20px;
}

.suggestion-container{
  position: absolute;
  border: 1px solid #e4e7ea;
  background-color: #fff;
  z-index: 2;
  width: calc(100% - 30px);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  ul{
    margin: 0;
    padding: 0;
    list-style-type: none;

    li{
      cursor: pointer;
      padding: 10px 20px;
    }

    li:hover{
      background-color: lightgray;
    }
  }
}

.star-field{
  color: red;
}

// FLEX CLASSES ====================================

.flex{
  display: flex;
}

.row{
  display: flex;
  flex-direction: row;
}

.flex-column{
  display: flex;
  flex-direction: column;
}

.center{
  justify-content: center;
}

.text-center{
  text-align: center;
}

.space-between{
  justify-content: space-between;
}

.align-center{
  align-items: center;
}

.align-end{
  align-items: flex-end;
}

.start{
  justify-content: flex-start;
}

.end{
  justify-content: flex-end;
}

// MAMU CSS ========================================

.mamu-button{
  border: 0;
  background-color: $mamu-button;
  padding: 5px 40px;
  border-radius: 5px;
  text-transform: uppercase;
  color: $button-color;
  font-family: 'Titillium Web', sans-serif;
}

.mamu-button.dark{
  background-color: $button-color;
  color: white;
  min-width: 150px;
}

.filter-buttons, .select-all-button{
  background-color: $th-bg;
  border-radius: 6px;
  color: $event-text;
  font-weight: normal;
  cursor: pointer;
  font-family: 'Titillium Web', sans-serif;
}
.filter-buttons:first-of-type{
  margin-bottom: 5px;
}
.select-all-button{
  font-size: 11px;
  font-weight: bold;
  padding: 3px;
}
tbody .filter-buttons{
  width: 21px;
  border-radius: 0;
}

.small-sub-text{
  font-family: 'Titillium Web', sans-serif;
  font-size: 12px;
  color: #b7b7b7;
}

// HEADER ==========================================

.app-header.navbar{
  border: 0;
  height: $header-height;
  background: transparent;

  .nav-bar{
    h3{
      margin: 0;
      font-size: 24px;
      color: $event-text;
      font-family: 'Titillium Web', sans-serif;
      text-transform: uppercase;
    }
    .current-location{
      margin: 0;
      font-size: 24px;
      color: $event-text;
      font-family: 'Titillium Web', sans-serif;
    }
  }
}

// DELIVERY ========================================

.delivery{
  .selected-delivery{
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 160px;
    text-align: center;
    span{
      color: $event-text;
      font-family: 'Titillium Web', sans-serif;
    }
  }
  .tabs{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-left: auto;
    margin-right: 3%;
  }
  .hourRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
  }
  .hourRow:nth-of-type(even) {
    background-color: $accent-color;
  }
  .item-tab-content{
    background-color: $main-color;
    font-family: 'Titillium Web', sans-serif;
    color: $event-text;
    width: 100%;
    flex-direction: column;
    padding: 0;
    .delivery-time{
      width: 25%;
      color: $secondary-text-color;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      cursor: pointer;
      &:hover {
        border: 1px solid red;
      }
    }

    @media (max-width: 768px) {
        .delivery-time {
          font-size: 6px;
        }
    }
    /*.delivery-time:nth-child(odd){
      background-color: $mamu-button;
    }*/
  }
}

// EVENTS ==========================================

.event{
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 5px 10px;
}

.events{
  div{
    color: $event-text;
    font-family: 'Titillium Web', sans-serif;
  }
  .event{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $event-bg;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 5px 30px;
    cursor: pointer;
  }
}

.single-event{
  div{
    color: $event-text;
    font-family: 'Titillium Web', sans-serif;
  }
  button{
    background-color: $button-color;
    border-radius: 6px;
    border: 0;
    font-family: 'Titillium Web', sans-serif;
    font-size: 12px;
    min-height: 30px;
    color: white;
  }
  .event-info-text{
    color: red;
    font-family: 'Titillium Web', sans-serif;
    font-size: 13px;
    font-weight: bold;
    text-align: center;
  }
}

// LOGIN ===========================================

.login{
  background-color: $main-color;

  input{
    box-shadow: inset 0 1px 3px 0 rgba(0,0,0,0.5);
  }

  .login-logo{
    background: url("../assets/img/logo.png") no-repeat scroll 50% 50%;
    width: 100%;
    height: 120px;
    margin-bottom: 30px;
    background-size: contain;
  }

  .sub-container{
    min-width: 450px;

    label{
      text-transform: uppercase;
      font-family: 'Titillium Web', sans-serif;
      color: #314A75;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
    }
  }

  .footer{
    margin-top: 30px;
    margin-bottom: 30px;
    //justify-content: space-between;

    button{
      min-width: 200px;
      background-color: $button-color;
      border-radius: 6px;
      border: 0;
      font-family: 'Titillium Web', sans-serif;
      font-size: 12px;
      min-height: 30px;
    }
  }

  .register-new{
    background-color: $event-bg;
    padding: 30px;
    label{
      margin: 0;
      min-width: 220px;
      text-align: start;
    }
    div.flex-row.space-between.align-items-center{
      padding-bottom: 10px;
    }
    div.flex-row.start{
      label{
        line-height: 14px;
        padding-left: 10px;
      }
    }
  }
  .register{
    button{
      width: auto;
    }
  }

  label.ticket-text{
    font-size: 14px;
  }
}

// PROFILE ========================================

.profile{
  .register-new{
    background-color: $main-color;
    padding: 30px;
    width: 570px;
    label{
      margin: 0;
      min-width: 220px;
      text-align: start;
      font-family: 'Titillium Web', sans-serif;
    }
    div.flex-row.space-between.align-items-center{
      padding-bottom: 10px;
    }
    div.flex-row.start{
      label{
        line-height: 14px;
        padding-left: 10px;
        font-family: 'Titillium Web', sans-serif;
      }
    }
    input{
      box-shadow: inset 0 1px 3px 0 rgba(0,0,0,0.5);
    }
  }
  button{
    width: auto;
    font-family: 'Titillium Web', sans-serif;
  }
  .tab-imitation{
    float: right;
    border: none;
    outline: none;
    padding: 5px 35px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background-color: $main-color;
    margin-right: 33px;
    font-family: 'Titillium Web', sans-serif;
    text-transform: uppercase;
    color: $event-text;
    font-size: 12px;
  }
  .register-new.blue{
    background-color: $event-bg;
    width: 420px;
    border-radius: 6px;
    margin-left: 100px;
    p{
      font-family: 'Titillium Web', sans-serif;
      color: $event-text;
      text-align: center;
    }
    input{
      box-shadow: inset 0 1px 3px 0 rgba(0,0,0,0.5);
    }
    input::placeholder{
      color: $event-bg;
    }
    button{
      background-color: $main-color;
      color: $event-text;
    }
  }
}

// ITEM =============================================

.item{
  .item-container{
    width: 100%;
    max-width: 1200px;
  }
  .register-new{
    width: 100%;
    justify-content: space-between;
    label{
      min-width: 130px;
    }
    div.flex-row.start{
      padding-top: 10px;
      padding-bottom: 20px;
      label{
        padding-left: 0;
      }
    }
  }
}

// SIDEBAR ==========================================

.sidebar .sidebar-nav{
  width: 230px;
}

.sidebar .nav{
  width: 230px;
}

html:not([dir="rtl"]) .sidebar{
  margin-left: -230px;
}

// RESPONSIVE =======================================

.table-responsive {
  border: none;
}

@media (max-width: 1450px) {
  .show-on-respone{
    display: table-row;
  }
  .show-on-full{
    display: none;
  }
}

@media (max-width: 1350px) {
  .profile{
    .register-new{
      width: 100%;
      label{
        min-width: 100px;
      }
    }
    .register-new.blue{
      width: 100%;
      margin-left: 20px;
    }
  }
}

@media (max-width: 1300px) {
  .profile{
    .register-new{
      width: 100%;
      label{
        min-width: 100px;
      }
    }
    .register-new.blue{
      width: 100%;
      margin-left: 20px;
    }
  }
  .table-responsive{
    overflow-x: auto;
  }
}

@media (max-width: 991.98px) {
  .sidebar {
    width: 230px;
  }
}

@media (max-width: 850px) {
  .profile{
    align-items: normal;
    flex-direction: column;
    .register-new{
      width: 100%;
      label{
        min-width: 100px;
      }
    }
    .register-new.blue{
      width: 100%;
      margin-left: 0;
      margin-top: 20px;
    }
  }
}

@media (max-width: 790px) {
  .login .sub-container{
    width: 100%;
    min-width: auto;
    padding: 20px;
  }
  .login .sub-container.register{
    width: 100%;
    min-width: auto;

    .reg-field{
      flex-direction: column !important;
      align-items: flex-start !important;
    }
  }
}

@media (max-width: 620px) {
  .items .action-sub.resp{
    flex-direction: column;
    button{
      margin-bottom: 10px;
    }
  }
  .app-header.navbar .nav-bar{
    font-size: 12px !important;

    p.my-place{
      font-size: 12px;
      font-family: 'Titillium Web', sans-serif;
      margin-bottom: 0;
    }

    .mamu-button{
      padding: 5px 15px;
    }

    .current-location{
      font-size: 14px;
    }
  }
}

@media (max-width: 600px) {
  .profile.item .item-container .register-new{
    flex-direction: column !important;
  }
}

@media (max-width: 575px) {
  .full-view{
    display: none !important;
  }
  .responsive-view{
    display: flex !important;
  }
  .sidebar {
    margin-top: 0;
  }
  .app-body.sub .action-sub.item-history{
    flex-direction: column;
  }
  .app-body.sub .action-sub.item-history button{
    margin-bottom: 10px;
  }
  .footer.item-footer{
    justify-content: center;
  }
}

@media (max-width: 400px) {
  .profile .profile-field{
    flex-direction: column !important;
    align-items: flex-start !important;
  }
}

@media (min-width: 992px) {
  .sidebar-fixed .sidebar{
    width: 230px;
  }
  html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .main, html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .app-footer, html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .main, html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .app-footer{
    margin-left: 230px;
  }
  .header-fixed .app-body {
      margin-top: $header-height;
  }
}

@media (min-width: 768px) {
  html:not([dir="rtl"]) .sidebar-md-show.sidebar-fixed .main, html:not([dir="rtl"]) .sidebar-md-show.sidebar-fixed .app-footer, html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .main, html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .app-footer{
    margin-left: 230px;
  }
}
@media (min-width: 576px){
  html:not([dir="rtl"]) .sidebar-sm-show.sidebar-fixed .main, html:not([dir="rtl"]) .sidebar-sm-show.sidebar-fixed .app-footer, html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .main, html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .app-footer{
    margin-left: 230px;
  }
}
