
.app-header.navbar {
  position: unset;
  flex: none;
}
.appLogo {
  cursor: pointer;
}
.nav-bar{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  min-height: 72px;
  background-color: $accent-color;
  padding: 0 73px;

  .person {
    display: flex;
    flex-direction: row;
    align-items: center;

    .avatar {
      margin-right: 21px;
      height: 50px;
      width: 36px;
    }
    .credential-container{
      display: flex;
      flex-direction: column;

      .credentials {
        font-size: 24px;
        line-height: 26px;
        color: $secondary-text-color;

        span {
          font-weight: 600;
        }
      }
      .event-id{
        font-size: 13px;
      }
    }
  }
  .currentEvent {
    height: 40px;
    padding: 10px;
    min-width: 150px;
    width: auto;
    color: $secondary-text-color;
    font-size: 16px;
    text-align: center;
    background-color: white;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;

  }
  .logoutButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $secondary-text-color;
    height: 72px;
    width: 72px;
    -webkit-appearance: none;

    img {
      height: 30px;
      width: 35px;
    }

    .lt {
      font-weight: 600;
      font-size: 12px;
    }
    .en {
      font-size: 10px;
    }
  }

  @media (max-width: 425px) {
    .person .avatar {
      display: none;
    }
  }
  @media (min-width: 425px) {
    .person .avatar {
      display: block;
    }
  }
  @media (max-width: 768px) {
    padding: 0 10px;
    justify-content: flex-end;

    .person {
      margin-right: 5%;

      .avatar {
        height: 33px;
        width: 24px;
      }
      .credential-container {
        align-items: flex-start;

        .credentials {
          font-size: 12px;
        }
        .event-id{
          font-size: 7.5px;
        }
      }
    }

    .currentEvent {
      height: 28px;
      width: auto;
      min-width: unset;
      font-size: 10px;
      padding: 10px;
    }
    .logoutButton {
      .lt,.en {
        display: none;
      }
    }
    .appLogo {
      margin-right: auto;
      max-height: 43px !important;
    }
  }
}

.menuContainer {
  width: 100%;
  z-index: 201;

  i {
    border: solid white;
    border-width: 0 4px 4px 0;
    display: inline-block;
    padding: 6px;
    position: absolute;
    right: 30px;
    border-radius: 4px;
  }
  .up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    bottom: 7px;
  }
  .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    top: 9px;
  }

  .menu {
    width: 100%;
    background-color: $sub-accent-color;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
    padding: 0 73px;
    height: 168px;
    overflow-y: hidden;
    transition: height 0.2s linear;
  }
  .menu::-webkit-scrollbar {
    display: block;
  }
  .menu.closed {
    height: 0;
  }

  .menuItem {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 139px;
    width: auto;
    border-radius: 6px;
    white-space: nowrap;
    padding: 16px 15px 10px 15px;
    color: $secondary-text-color;
    line-height: 18px;
    cursor: pointer;
    -webkit-appearance: none;

    img {
      padding-bottom: 10px;
    }

    .lt {
      font-size: 12px;
    }
    .en {
      font-size: 10px;
    }
  }
  .menuItem:hover, .selected {
    background-color: $secondary-accent-color;
    .lt {
      color: white;
    }
    .en {
      color: $accent-color;
    }
  }
  .menuItem:last-of-type {
    border: 2px solid $accent-color;
  }
  .menuItem[disabled] {
    pointer-events: none;
    opacity: 0.3;
  }

  .menuLine {
    background-color: $secondary-accent-color;
    width: 100%;
    height: 8px;
  }

  .menuButton {
    position: relative;
    background-color: $secondary-accent-color;
    height: 40px;
    width: 343px;
    border-bottom-right-radius: 6px;
    -webkit-appearance: none;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;

    .lt {
      color: white;
    }
    .en {
      color: $accent-color;
    }
  }

  @media (max-width: 768px) {
    .menu {
        padding: 0;
        justify-content: unset;
        overflow: auto;
    }
    .menuButton {
      width: 100%;
      border-radius: unset;
    }
  }
}

.app-body.sub{
  display: flex;
  flex-grow: 0;
  flex-direction: column;
  margin-top: 0;
  min-height: 0;
  justify-content: center;

  .action-sub{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    button{
      margin-left: 10px;
      text-transform: none;
    }
  }
  .body-sub{
    margin-top: 10px;
    width: 100%;
  }

  .body-sub{
    font-family: 'Titillium Web', sans-serif;
    color: $nav-sub-text;
    text-align: justify;
  }
  .body-sub-area{
    border: none;
    resize: none;
    width: 100%;
  }
}
