.footer {
    height: 9%;
    width: 100%;
    background-color: $secondary-accent-color;
    color: white;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    z-index: 1000;

    * {
        margin: 0;
        padding: 0;
    }

    @media (max-width: 768px) {
        font-size: 9px;
    }
}